/**************************
Table of Contents

Base Styles
Map Styles
Page Styles
Tabs
Thumbs
Model, Carousel
Puzzle, Gallery
Error Messages
Scrollbar
Media Queries
Animations

***************************/

/* Base Styles */

html {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  scrollbar-width: none;  /* Firefox */
}
body {
  color: #FFC107;
  background: #000;
  overflow: overlay;
  /* scrollbar-color: #FFC107 #000;
  scrollbar-width: thin; */
}
h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 1.1;
  padding: 1em 0 0.5em;
}
h3 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  padding: 2em 0 0.7em;
}
p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  line-height: 1.4;
  margin: 0 0 1em 0;
}
a,
a:visited {
  color: #FFC107;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #FFC107;
  text-decoration: underline;
}

nav.fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  bottom: auto;
  z-index: 5;
}
nav ul {
  margin-block-start: unset;
  margin-block-end: unset;
}
nav li {
	display: block;
  list-style: none;
  margin: .5em;
  line-height: 2.5em;
  padding-left: .8em;
}

nav a,
nav a:visited,
nav a:focus {
    color: #FFC107;
    transition: .3s;
}

nav a:hover {
    color: rgba(255,193,7,.5);
    text-decoration: none;
    transition: .3s color;
}

nav a:hover path {
    fill: rgba(255,193,7,.5);
    transition: .3s fill, .3s stroke;
}

nav a:hover path.stroke {
    stroke: rgba(255,193,7,.5);
}

nav li {
  border-left: 3px #000000 solid;
}

nav a.active-tab li {
    border-left: 3px #FFC107 solid;
}

main,
header {
  padding-right: 1em;
}
header.title-bar {
  height:10vh;
}
main {
  max-height: 90vh;
  overflow-y:scroll; /*cross browser alternative to always show scrollbar so tabs dont shift*/
  overflow-y:overlay; /*apparently deprecated, but makes it so the tabs dont shift whether or not there is scrollbar*/

}
main.full-height {
  max-height: 100vh;
}
form {
  text-align: center;
}

button {
  margin: .5em;
}

input,
button,
[type=submit] {
  color:#FFC107;
  font-size: 1.12em;
  line-height: 2em;
  padding: 0 .5em
}

button,
[type=submit],
[type=submit]:focus {
  color:#FFC107;
  border-color: #FFC107;
  background: #000;
  text-align: center;
  outline: none;
  display:inline-block;
}

button:hover,
button:focus {
  color: black;
  background-color: #FFC107;
}

input {
  margin:1em 1em 1em 0;
  border-color: rgba(25,25,25);
  color:#FFC107;
  caret-color:#FFC107;
  width: 75%;
  display: inline-block;
}

input:active,
input:focus {
  border-color: #FFC107;
}

svg {
  height: 100vh;
  width: auto;
}
path {
  fill: #000;
  transition: .6s fill;
}

.svg-icon svg path {
  fill: #FFC107;
}
.svg-icon svg:hover {
  opacity: 0.8;
}
.svg-icon path {
  fill: #FFC107;
}
.svg-icon path.stroke {
  stroke: #ffc107;
  fill: none;
  stroke-width: 3px;
  stroke-miterlimit: 10;
}

.svg-icon {
  align-items: center;
  display: flex;
  height: 1.8em;
}
.svg-icon svg {
  height: 32px;
  width: 30px;
}
button .svg-icon {
  display: inline-flex;
  padding: 0;
  width: 1.5em;
  vertical-align: top;
}
button .svg-icon svg {
  max-height: 30px;
  width: auto;
  margin: 0 auto;
}

nav .svg-icon {
  display: flex;
  align-items: center;
  height: 3em;
}
nav .svg-icon svg {
  height: 2em;
  width: 2em;
}


button:hover .svg-icon path,
button:active .svg-icon path,
.react-tabs__tab:hover .svg-icon path,
.react-tabs__tab:active .svg-icon path {
  fill: #000;
}


/* Map Styles */

.figure-outline { fill: #FFC107; }
.active path {fill: #FFC107; }
.inactive path {fill: #000; }
.clickable:hover { fill: #FFC107; }
.active .reverse { fill: #000; }
.visited { fill: rgb(254 193 8 / 41%); }

/* Page Styles */

.title-bar {
  align-items: center;
  border-bottom: 3px solid #FFC107;
  display: flex;
  padding: 0 1em;
}
.title-bar h2 {
  padding: 0;
}

.archive-entry {
  overflow-y: auto;
  line-height: 1.8em;
  padding-top: 1.5em;
}
.archive-entry .columns{
  margin-left: 0;
  margin-right: 0;
}

.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.key {
  font-weight: bold;
  margin-bottom: 2em;
  padding-left: 1em;
}
.value {
  margin-bottom: 20px;
  padding: 0 1em;
}
.page {
  padding: 1em;
}
.centered {
  display: table;
  margin: 0 auto;
}

/* Tabs */
.react-tabs__tab:focus:after {
  background: none;
}
.react-tabs__tab {
  border-left: 1px solid #FFC107;
  border-right: 1px solid #FFC107;
  border-top: 1px solid #FFC107;
  border-radius: 5px 5px 0 0;
  transition: transform 0.1s, background 0.4s, border-color 0.4s, color 0.4s, box-shadow 0.4s;
  vertical-align: bottom;
}
.react-tabs__tab--selected,
.react-tabs__tab:focus,
.react-tabs__tab:hover {
  background: #FFC107;
  /*border-bottom: 1px solid #FFC107; bug: this makes the icon tabs shift up, but not the text tabs*/
  border-color: #FFC107;
  border-radius: 5px 5px 0 0;
  box-shadow: none;
  color: black;
}
.react-tabs__tab-list {
    border-bottom: 1px solid #FFC107;
}
.react-tabs__tab--disabled {
  display: none;
}
.react-tabs__tab--selected .svg-icon svg path {
  fill: #000;
}

/* Thumbs */

.thumbsList {
  width: 100%;
}
.thumb {
  float: left;
  padding: 1em;
  text-align: center;
}
.thumb img:hover {
  cursor: pointer;
  opacity: 0.9;
}
.imgWrapper img {
  max-width: 100%;
  height: 100%;
}
.thumbSize {
  text-align: center;
  display: block;
}

/* Modal, Carousel */

.activeModal,
.modalOverlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  text-align: center;
  padding: 1em;
  z-index: 6;
}

.modalContent{
  position: static;
  width: auto;
  height: auto;
  display: contents;
}

.inactiveModal  {
   display: none;
}

.modalContentInner {
  background: rgba(0,0,0,.3);
  border: solid 2px;
  border-radius: 1em;
  padding: 0;
  text-align: center;
  margin: 0 auto;
  display:inline-block;
  position:relative;
  direction:ltr;
}

.modalContent:focus {
  outline: none;
}

#passInner .modalContentInner {
  margin: 12vh auto;
  width: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 3em 5em;
}

.modalButton,
button.modalButton,
button.modalButton:focus {
  border: solid 2px;
  border-radius: .3em;
  background: #000;
  line-height: 2em;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  color: #FFC107;
  width: 2em;
  height: 2em;
  padding: 0;
  margin: 0;
}

button:hover,
.modalButton:hover,
[type=submit]:hover {
  /* background: rgba(25,25,25);
  color: #FFC107; */
  color: black;
  background-color: #FFC107;
  border-color: #FFC107;
}

button:active,
.modalButton:active,
#modalClose:active .modalButton,
#prevCarousel:active .modalButton,
#nextCarousel:active .modalButton,
[type=submit]:active {
  background:#FFC107;
  color:#000;
  border-color: #FFC107;
}

#modalClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0 3em 3em;
  margin: 0;
  z-index: 10;
}

#modalClose .modalButton {
  border-radius: 0 1em 0 .3em;
  margin: -.1em;
  width: auto;
  height: auto;
  line-height: inherit;
  padding: 3px 4px 5px 4px;
}

#modalClose .svg-icon {
  width: auto;
  height: auto;
}

#carousel {
  padding: .5em;
  height: 100%;
}

#prevCarousel,
#nextCarousel {
  position: absolute;
  top: 50%;
  left: auto;
  width: 8em;
  cursor: pointer;
  padding: 6em 1em;
  margin-top: -10em;
}

#prevCarousel .modalButton,
#nextCarousel .modalButton {
  height: 10em;
  line-height: 10em;
}

#prevCarousel {
  left: -1em;
  text-align: left;
  margin-left: -1em;
}

#nextCarousel {
  right: -1em;
  text-align: right;
  margin-right: -1em;
}

/* Puzzle, Gallery */

.mainPuzzleContent {
  margin: 10vh auto;
  padding: 10px;
}

.mainPuzzleContent h3,
#passInner h3 {
  font-size: 1.25em;
  padding: 0;
  margin: 1em 0 .3em 0;
}

.puzzleImage {
  width: 100%;
  height: 40vh;
  background-color: #000;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
  max-height: 600px;
}

#modalGallery {
  /*width: 100%;
  height: 100%;
  margin: 0 auto;*/
}

#galleryInner {
  width: 100%;
  height: 100%;
}

#activeImage {
  width: 100%;
  height: 100%;
  white-space: nowrap; /* This is required unless you put the helper span closely near the img */
  text-align: center;
}

#activeImage img {
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

/* Error Messages */

.errorMsg {
  color: #000000;
  opacity: 0;
  direction: rtl;
  transition: opacity .4s, color .4s;
}

.activeError input {
  border-color: #ff0000;
}

.activeError .errorMsg {
  color: #ff0000;
  opacity: 1;
}

.activeError .shakeable {
  animation: shake 0.4s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

/* Hide Scrollbar for Chrome, Safari and Opera */

::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
    background-color: transparent;
    opacity: 1;
}

.entry-inner::-webkit-scrollbar-track,
main::-webkit-scrollbar-track {
  border: 1px solid rgba(255,193,7,.75);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: rgb(25,25,25);
}

.entry-inner::-webkit-scrollbar,
main::-webkit-scrollbar {
  width: 6px;
  background-color: rgb(25,25,25);
}

.entry-inner::-webkit-scrollbar-thumb,
main::-webkit-scrollbar-thumb {
  background-color: #FFC107;
}

/* Media Queries */

@media only screen and (max-width: 991px) {
  header.title-bar{
    height: auto;
  }
  main {
    padding-bottom: 50px; /*avoids overlap with bottom nav*/
    max-height: none;
  }
  nav,
  nav.fixed {
    display: block;
    margin: 0;
    position: fixed;
    top: unset;
    bottom: 0;
    right: 0;
    height: 60px; /*avoid overlap by setting padding-bottom on main*/
    width: 100%;
    background: #000;
    text-align: center;
  }
  nav li {
    padding: 0;
  	display: inline-block;
    border-left: none;
    border-top: 3px #000000 solid;
  }
  nav a.active-tab li {
    border-left: none;
    border-top: 3px #FFC107 solid;
    margin: 0 .5em;
    line-height: 2em;
  }
  nav .svg-icon {
    padding: 6px 0;
  }

  nav .svg-icon svg {
    margin: .5em auto;
  }
  .archive-entry {
    max-height: 100%;
    overflow-y: initial;
  }
  .figure {
    padding-bottom: 70px;
  }
  .key {
    margin-bottom: 1em;
  }
  .thumb {
    float: left;
    padding: .5em;
  }
  .imgWrapper {
    height: 125px;
  }

  .mainPuzzleContent {
    margin: 5vh auto;
  }

  .puzzleImage {
    min-height: auto;
    max-height: none;
  }

  .mainPuzzleContent input {
    margin: 0;
  }

}

@media only screen and (max-width: 667px) {
  #passInner .modalContentInner {
    margin: 5vh auto;
    padding: 1em;
  }
}


/* Animations */

@keyframes shake {
  0%, 100% {
    transform: translate3d(0px, 0, 0);
  }
  10%, 30% {
    transform: translate3d(-3px, 0, 0);
  }
  20%, 40% {
    transform: translate3d(3px, 0, 0);
  }
}
